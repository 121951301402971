<template>
<b-row>
    <b-col cols="12">
        <h2>Account Top Up</h2>
        <p>You can now top up your account via Mpesa. To top up your account, enter amount and click submit to receive stk push</p>

        <b-card>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
            <b-form @submit.prevent>
                <b-row>
                    <b-col md="12">
                        <b-form-group>
                                <b-form-input v-model="phone" type="number" />
                           
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group>
                            <validation-provider #default="{ errors }" >
                                <b-form-input v-model="totalAmount" type="number" :state="errors.length > 0 ? false:null" placeholder="Enter callback url" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- reset and submit -->
                    <b-col cols="12">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" id="accountTop" @click="validationForm">
                        <b-spinner small v-show="isLoading" />
                            Top Up Now!
                        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </b-card>
    </b-col>
    <b-col cols="12 hidden">
        <p style="text-align:center"><strong>OR</strong></p>
        <b-card>
            <p>Follow below instructions to make payments</p>
            <ol>
                <li>Go to M-PESA Menu on your mobile phone</li>
                <li>Select Lipa na M-PESA</li>
                <li>Select Pay Bill</li>
                <li>Enter <strong>402</strong> as the Business Number</li>
                <li>Enter <strong><em>FLK061</em></strong> as Account Number</li>
                <li>Enter the amount <strong><em>{{totalAmount}} </em></strong> to pay </li>
                <li>Enter your M-PESA PIN and send</li>
                <li>You will receive an SMS confirming the transaction</li>
            </ol>
        </b-card>
    </b-col>

</b-row>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'
import $ from 'jquery'
import store from '@/store/index'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCard,
        BSpinner,
        BAlert,
    },
    data() {
        return {
            totalAmount: 5000,
            channelID:1,
            modePay:"MOMO",
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
            isLoading:false,
            phone:""
        }
    },
    mounted(){
        this.phone = this.$cookies.get("clientData").mobile
    },
    directives: {
        heightFade,
        Ripple,
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        validationForm() {
            let vm = this
           
            if (this.totalAmount == "" || this.totalAmount < 0) {
                this.$swal({
                    title: 'Error!',
                    text: ' You need to enter amount',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            } else {
                 vm.isLoading = true
                 $('#accountTop').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'account/v1/account_topup',
                    post: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    data: JSON.stringify({
                        amount: vm.totalAmount,
                        channelId: vm.channelID,
                        msisdn: vm.phone,
                        clientId:vm.$cookies.get("clientData").clientId,
                        modePay: vm.modePay
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#accountTop').html('Submit Request');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message

                        } else {
                            vm.$swal({
                                title: 'Success!',
                                text: response.data.message,
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                            vm.$router.push({
                                name: 'billing-tops'
                            });
                        }

                    },
                    error: function (jQxhr, status, error) {
                        
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                        $('#accountTop').html('Submit Request');
                        var errorMessage = jQxhr.responseJSON.data.message;
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vm.showDismissibleAlert = true;
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + errorMessage;
                        }
                    }
                });
            }
        },
    },
}
</script>
